import { atom } from "jotai";
import type { People, Post } from "~/sdk/qs1/index.server";

/** Socio-Economic Statuses */
export const socioEconomicStatusesAtom = atom<string[]>([
  "8(a)",
  "HUBZone",
  "African American Owned",
  "Woman Owned",
  "EDWOSB - Economically Disadvantaged Women-Owned Small Business",
  "Veteran",
  "Service Disabled Veteran",
  "Native Indian American Owned",
  "Minority Owned",
  "Asian Pacific American Owned",
  "Subcontinent Asian American Owned",
  "Hispanic American Owned",
]);

/** States */
export const statesAtom = atom<string[]>([
  "NV - Nevada",
  "OH - Ohio",
  "NY - New York",
  "WV - West Virginia",
  "AR - Arkansas",
  "CT - Connecticut",
  "WY - Wyoming",
  "LA - Louisiana",
  "MT - Montana",
  "MI - Michigan",
  "DE - Delaware",
  "NH - New Hampshire",
  "OR - Oregon",
  "DC - District of Columbia",
  "WI - Wisconsin",
  "RI - Rhode Island",
  "AP - Armed Forces Pacific",
  "TN - Tennessee",
  "WA - Washington",
  "MA - Massachusetts",
  "AL - Alabama",
  "CO - Colorado",
  "AS - American Samoa",
  "VA - Virginia",
  "AZ - Arizona",
  "ND - North Dakota",
  "AE - Armed Forces Europe",
  "GU - Guam",
  "HI - Hawaii",
  "IN - Indiana",
  "NE - Nebraska",
  "FL - Florida",
  "ME - Maine",
  "SD - South Dakota",
  "NJ - New Jersey",
  "MP - Northern Mariana Islands",
  "UT - Utah",
  "SC - South Carolina",
  "VI - U.S. Virgin Islands",
  "CA - California",
  "TX - Texas",
  "PR - Puerto Rico",
  "KY - Kentucky",
  "NM - New Mexico",
  "MS - Mississippi",
  "MO - Missouri",
  "NC - North Carolina",
  "OK - Oklahoma",
  "ID - Idaho",
  "GA - Georgia",
  "MN - Minnesota",
  "PA - Pennsylvania",
  "MD - Maryland",
  "AK - Alaska",
  "IL - Illinois",
  "IA - Iowa",
  "VT - Vermont",
  "KS - Kansas",
]);

/** Agencies */
const defenseAgencies = [
  "Air Force",
  "Army",
  "Navy",
  "DARPA",
  "DECA",
  "DCAA",
  "DCMA",
  "DFAS",
  "DISA",
  "DIA",
  "DLSA",
  "DLA",
  "DSCA",
  "DCSA",
  "DTRA",
  "MDA",
  "NGA",
  "NRO",
  "NSA/CSS",
  "CSS",
  "PFPA",
];

const healthAgencies = ["CMS", "HHS", "MHS", "NIH", "VA", "CDC", "FDA"];

const fedcivAgencies = [
  "DHS",
  "DOC",
  "DOE",
  "DOI",
  "DOJ",
  "DOL",
  "DOS",
  "DOT",
  "ED",
  "EPA",
  "GSA",
  "NASA",
  "OPM",
  "SBA",
  "SSA",
  "Treasury",
  "USAID",
  "USDA",
  "FDIC",
];

export const agenciesAtom = atom<Map<string, string[]>>(
  new Map([
    ["defense", defenseAgencies],
    ["health", healthAgencies],
    ["fedciv", fedcivAgencies],
  ]),
);

export const agencyMap = new Map([
  ["defense", defenseAgencies],
  ["health", healthAgencies],
  ["fedciv", fedcivAgencies],
]);

export const AgencyMap = {
  "Air Force": "5700", // Air Force
  Army: "2100", // Army
  Navy: "1700", // Navy
  DARPA: "97AE", // DARPA
  DCMA: "9763", // DCMA
  DECA: "97AZ", // DECA
  DFAS: "97BZ", // DFAS
  DISA: "97AK", // DISA
  DLA: "97AS", // DLA
  DSCA: "97AT", // DSCA
  DTRA: "9761", // DTRA
  MDA: "97JC", // MDA
  NGA: "97AB", // NGA
  DCAA: "97AR", // DCAA
  "NSA/CSS": "97CG", // NSA/CSS
  DIA: "97DL", //DIA
  FDIC: "5100", // FDIC
};

/** Categories */
export const categoriesAtom = atom<string[]>(["Awards", "Industry Moves", "Jobs", "News", "Opportunities", "Protests"]);

export const exportModeAtom = atom<"pdf" | "none">("none");
export const exportIdsAtom = atom<string[]>([]);
export const handleExportIdsAtom = atom(
  // atom that handles export id operations
  (get) => get(exportIdsAtom), // getter
  (get, set, post: Post) => {
    // setter
    // get export ids
    const ids = get(exportIdsAtom);

    // determine whether to update or remove the id
    if (!ids?.includes(post.id)) {
      set(exportIdsAtom, [...ids, post.id]);
    } else {
      set(
        exportIdsAtom,
        ids?.filter((id) => id !== post.id),
      );
    }
  },
);

export const formLoadingAtom = atom(false);

export const peopleFiltersAtom = atom({
  search: "",
  companyId: "",
});

export const peopleAtom = atom<People[]>([]);
